import { useState, useCallback, useRef, type ChangeEvent, useMemo } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonText,
  IonInput,
} from '@ionic/react';
import { DataGrid } from './DataGrid';
import { ReadReceiptXlsxError, readReceiptXlsx } from './xlsx';
import { type CampaignInfo } from './campaign';
import { DateTime } from 'luxon';

export function CampaignSetupScreen({
  onStartSending,
}: {
  onStartSending: (campaignInfo: CampaignInfo) => void;
}) {
  const [error, setError] = useState<string>();
  const [parsedData, setParsedData] = useState<string[][]>();
  const [dataSrcFile, setDataSrcFile] = useState<File>();
  const [receiptDate, setReceiptDate] = useState(DateTime.now().toFormat('d MMMM y'));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    setError(undefined);
    setParsedData(undefined);
    setDataSrcFile(undefined);
    if (!file) return;

    try {
      setDataSrcFile(file);
      const fileData = await file.arrayBuffer();
      const extractedSheetData = readReceiptXlsx(fileData);
      extractedSheetData[0] = ['Email', 'Name', 'Company', 'Payment Type', 'Amount'];

      setParsedData(extractedSheetData);
    } catch (e) {
      if (e instanceof ReadReceiptXlsxError) {
        setError(e.message);
      } else {
        setError('File could not be read');
      }
    }
  }, []);

  const openFileDialog = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const campaignInfo = useMemo<CampaignInfo | undefined>(() => {
    if (!parsedData || !receiptDate) return undefined;
    const receiptInfos = parsedData.map(dataRow => {
      const [email, name, company, paymentType, amount] = dataRow;
      return { date: receiptDate, email, name, company, paymentType, amount };
    });

    return { receiptInfos };
  }, [parsedData, receiptDate]);

  const handleStartSendingClick = useCallback(() => {
    onStartSending(campaignInfo!);
  }, [campaignInfo, onStartSending]);

  const handleReceiptDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setReceiptDate(e.target.value);
  }, []);

  return (
    <IonCard>
      <IonCardContent>
        <h2 style={{ color: 'black', padding: '8px 0' }}>Receipt Date</h2>
        <input className="form-control" value={receiptDate} onChange={handleReceiptDateChange} />
        <br />
        <IonText>
          <h2 style={{ color: 'black', padding: '8px 0' }}>Data Source File (XLS, XLSX)</h2>
        </IonText>
        <div>
          <IonText>
            {dataSrcFile ? `File name: ${dataSrcFile.name}` : 'Select an Excel file to continue'}
          </IonText>
        </div>
        {error && (
          <div>
            <IonText color="danger">{error}</IonText>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <IonButton
          size="default"
          color="secondary"
          onClick={openFileDialog}
          style={{ padding: '8px 0' }}
        >
          Select
        </IonButton>
        <br />
        <br />
        {parsedData && (
          <>
            <DataGrid data={parsedData} />
            <br />
          </>
        )}
        <IonButton
          expand="block"
          size="default"
          color="primary"
          disabled={!campaignInfo}
          onClick={handleStartSendingClick}
        >
          Start Sending
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
}
