import { useCallback, useEffect, useState } from 'react';
import { settingsOutline } from 'ionicons/icons';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
} from '@ionic/react';
import './App.css';
import { SettingsModal } from './SettingsModal';
import { CampaignSetupScreen } from './CampaignSetupScreen';
import { CampaignSendScreen } from './CampaignSendScreen';
import { loadSettings } from './settings';
import { type CampaignInfo } from './campaign';

function App() {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const onSettingsDismiss = useCallback(() => {
    setIsSettingsOpen(false);
  }, []);

  const handleSettingsClick = useCallback(() => {
    setIsSettingsOpen(true);
  }, []);

  useEffect(() => {
    if (!loadSettings()) setIsSettingsOpen(true);
  }, []);

  const [campaignInfo, setCampaignInfo] = useState<CampaignInfo | undefined>();
  const handleStartSending = useCallback((newCampaignInfo: CampaignInfo) => {
    setCampaignInfo(newCampaignInfo);
  }, []);

  const handleCampaignCancel = useCallback(() => {
    setCampaignInfo(undefined);
  }, []);

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>BNI Unity Receipt Mailer</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={handleSettingsClick}>
              <IonIcon icon={settingsOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!campaignInfo && <CampaignSetupScreen onStartSending={handleStartSending} />}
        {campaignInfo && (
          <CampaignSendScreen campaignInfo={campaignInfo} onCancel={handleCampaignCancel} />
        )}
      </IonContent>
      <SettingsModal isOpen={isSettingsOpen} onDismiss={onSettingsDismiss} />
    </IonPage>
  );
}

export default App;
