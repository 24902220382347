import { useEffect, useRef } from 'react';
import { type ReceiptInfo } from './campaign';
import { renderReceiptImage } from './receipt-image';

export function ReceiptImageRenderer({ receiptInfo }: { receiptInfo: ReceiptInfo }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasElm = canvasRef.current;
    if (!canvasElm) return;

    void renderReceiptImage(canvasElm, receiptInfo);
  }, [receiptInfo]);

  return <canvas ref={canvasRef} width={930} height={474.375} style={{ width: '100%' }} />;
}
