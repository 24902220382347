import { useMemo } from 'react';

export function DataGrid({ data }: { data: string[][] }) {
  const [header, rows] = useMemo(() => {
    const header = data[0] ?? [[]];
    const rows = data.slice(1);
    return [header, rows];
  }, [data]);

  return (
    <div style={{ overflow: 'scroll', maxHeight: 300 }}>
      <table className="table">
        <thead>
          <tr>
            {header.map((value, colIndex) => (
              <th key={colIndex} scope="col">
                {value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((value, colIndex) => (
                <td key={colIndex}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
