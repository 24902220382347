import { type ReceiptInfo } from './campaign';
import receiptBgImage from './receipt-bg.png';

export const receiptWidth = 930;
export const receiptHeight = 474.375;

const receiptBgImageLoading = new Promise<HTMLImageElement>((resolve, reject) => {
  const receiptBg = new Image();
  receiptBg.onload = () => {
    resolve(receiptBg);
  };

  receiptBg.src = receiptBgImage;
});

export async function renderReceiptImage(canvasElm: HTMLCanvasElement, receiptInfo: ReceiptInfo) {
  const canvas = canvasElm.getContext('2d')!;
  if (!canvas) return;

  canvas.clearRect(0, 0, canvasElm.width, canvasElm.height);

  const receiptBg = await receiptBgImageLoading;
  canvas.drawImage(receiptBg, 0, 0, receiptWidth, receiptHeight);

  canvas.textAlign = 'left';
  canvas.textBaseline = 'bottom';
  canvas.font = '30px sans-serif';

  canvas.fillText(`${receiptInfo.company}`, 185, 220, 700);
  canvas.fillText(`${receiptInfo.amount}`, 185, 281, 700);
  canvas.fillText(`${receiptInfo.paymentType}`, 185, 360, 700);

  canvas.textAlign = 'left';
  canvas.textBaseline = 'bottom';
  canvas.font = '24px sans-serif';
  canvas.fillText(`${receiptInfo.date}`, 736, 138, 194);
}
