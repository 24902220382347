import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonCard,
  IonIcon,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonList,
  IonInput,
  IonTextarea,
  IonSpinner,
} from '@ionic/react';
import { peopleOutline, sendOutline, checkmarkOutline, alertCircleOutline } from 'ionicons/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type CampaignInfo } from './campaign';
import { ReceiptImageRenderer } from './ReceiptImageRenderer';
import { receiptHeight, receiptWidth, renderReceiptImage } from './receipt-image';
import { loadSettings } from './settings';
import { sendEmail } from './api';

type SendStatus = 'sending' | 'sent' | 'failed';
const isMockSend = false;

export async function mockSend() {
  return new Promise<boolean>(res => {
    setTimeout(() => {
      res(Math.random() > 0.2);
    }, Math.random() * 400);
  });
}

export function CampaignSendScreen({
  campaignInfo,
  onCancel,
}: {
  campaignInfo: CampaignInfo;
  onCancel: () => void;
}) {
  const handleCancelClick = useCallback(() => {
    // eslint-disable-next-line no-alert
    const isConfirm = confirm(
      'All current progress will be cleared, are you sure to cancel or finish?',
    );
    if (isConfirm) onCancel();
  }, []);

  const [messages, setMessages] = useState(campaignInfo.receiptInfos.slice(1));
  const [statuses, setStatuses] = useState<SendStatus[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [isSendingAll, setIsSendingAll] = useState(false);

  const setMessageState = useCallback((index: number, newStatus: SendStatus) => {
    setStatuses(statuses => {
      const newStatuses = [...statuses];
      newStatuses[index] = newStatus;
      return newStatuses;
    });
  }, []);

  const handleSendEmail = useCallback(
    async (index: number) => {
      if (isSending || ['sent', 'sending'].includes(statuses[index])) {
        return;
      }

      const message = messages[index];
      const canvasElm = document.createElement('canvas');
      canvasElm.width = receiptWidth;
      canvasElm.height = receiptHeight;
      await renderReceiptImage(canvasElm, message);

      const settings = loadSettings()!;
      const emailRequest = {
        sender: {
          gmail: settings.email,
          appPassword: settings.appPassword,
        },
        to: message.email,
        subject: 'Official Receipt from BNI Unity Chapter',
        body: `Dear ${message.name},

Please find the attached receipt, thank you.

Regards,
Unity ST team


Automated Solution Powered by https://kingly.solutions`.trim(),
        attachments: [
          {
            fileName: 'receipt.png',
            contentType: 'image/png',
            base64Data: canvasElm.toDataURL().substring(22),
          },
        ],
      };

      setIsSending(true);
      setMessageState(index, 'sending');
      const success = isMockSend ? await mockSend() : await sendEmail(emailRequest);

      if (success) {
        setMessageState(index, 'sent');
      } else {
        setMessageState(index, 'failed');
      }

      setIsSending(false);
    },
    [messages, statuses, isSending],
  );

  const handleToggleSendAllClick = useCallback(async () => {
    const newStatus = !isSendingAll;
    setIsSendingAll(newStatus);
  }, [isSendingAll, messages, statuses]);

  const [currentAutoSendIndex, setCurrentAutoSendIndex] = useState(0);
  useEffect(() => {
    if (isSendingAll) {
      if (currentAutoSendIndex >= messages.length) {
        setIsSendingAll(false);
        return;
      }

      void handleSendEmail(currentAutoSendIndex).then(() => {
        setCurrentAutoSendIndex(prevIndex => prevIndex + 1);
      });
    }
  }, [isSendingAll, messages, currentAutoSendIndex]);

  const totalCount = useMemo(() => messages.length, [messages]);
  const failedCount = useMemo(
    () => statuses.filter(status => status === 'failed').length,
    [statuses],
  );
  const sentCount = useMemo(() => statuses.filter(status => status === 'sent').length, [statuses]);
  const pendingCount = useMemo(
    () => totalCount - failedCount - sentCount,
    [totalCount, failedCount, sentCount],
  );

  return (
    <>
      <IonCard>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol className="hero">
                <h1 className="hero-big">Email Campaign</h1>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="hero">
                <div className="hero-big">{totalCount}</div>
                <div className="hero-small">
                  <IonIcon icon={peopleOutline} />
                  &nbsp;Total
                </div>
              </IonCol>
              <IonCol className="hero">
                <div className="hero-big">{pendingCount}</div>
                <div className="hero-small">
                  <IonIcon color="warning" icon={sendOutline} />
                  &nbsp; Pending
                </div>
              </IonCol>
              <IonCol className="hero">
                <div className="hero-big">{sentCount}</div>
                <div className="hero-small">
                  <IonIcon color="success" icon={checkmarkOutline} />
                  &nbsp; Sent
                </div>
              </IonCol>
              <IonCol className="hero">
                <div className="hero-big">{failedCount}</div>
                <div className="hero-small">
                  <IonIcon color="danger" icon={alertCircleOutline} />
                  &nbsp; Failed
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              {(isSendingAll || isSending) && (
                <div style={{ margin: '0 auto' }}>
                  <IonSpinner name="lines-sharp-small"></IonSpinner>
                </div>
              )}
            </IonRow>
            <IonRow>
              <IonCol>
                {!isSendingAll && (
                  <IonButton color="primary" expand="block" onClick={handleToggleSendAllClick}>
                    Send All
                  </IonButton>
                )}
                {isSendingAll && (
                  <IonButton color="warning" expand="block" onClick={handleToggleSendAllClick}>
                    Stop Send All
                  </IonButton>
                )}
              </IonCol>
              <IonCol>
                <IonButton color="danger" expand="block" onClick={handleCancelClick}>
                  Finish / Cancel
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonCard style={{ marginBottom: 50 }}>
        <IonAccordionGroup>
          {messages.map((message, index) => {
            const { email, name, paymentType } = message;
            const status: string | undefined = statuses[index];
            return (
              <IonAccordion key={index}>
                <IonItem slot="header" color="light">
                  <IonLabel>
                    {(() => {
                      let color: string;
                      let icon: string;
                      if (status === 'sending') {
                        return <IonSpinner name="lines-sharp-small"></IonSpinner>;
                      }

                      if (status === 'sent') {
                        color = 'success';
                        icon = checkmarkOutline;
                      } else if (status === 'failed') {
                        color = 'danger';
                        icon = alertCircleOutline;
                      } else {
                        color = 'warning';
                        icon = sendOutline;
                      }

                      return <IonIcon color={color} icon={icon} />;
                    })()}
                    &nbsp;&nbsp; {name} &lt;{email}&gt; - {paymentType}
                  </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <IonList>
                    <IonItem>
                      <IonInput label="To" value={email} readonly />
                    </IonItem>
                    <IonItem>
                      <IonInput
                        label="Subject"
                        value="Official Receipt from BNI Unity Chapter"
                        readonly
                      />
                    </IonItem>
                    <IonItem>
                      <IonTextarea
                        label="Body"
                        value={`Dear ${name},\n\nPlease find the attached receipt, thank you.\n\nRegards,\nUnity ST team`}
                        rows={6}
                        readonly
                      />
                    </IonItem>
                    <IonItem>
                      <div>
                        <div className="py-3">Attachment: receipt.png</div>
                        <div style={{ width: '80%', margin: '0 auto', position: 'relative' }}>
                          <ReceiptImageRenderer receiptInfo={message} />
                        </div>
                      </div>
                    </IonItem>
                  </IonList>
                  <div style={{ textAlign: 'right' }}>
                    {(() => {
                      if (status === 'sent') {
                        return (
                          <IonButton size="default" color="success" disabled>
                            <IonIcon slot="start" icon={checkmarkOutline} /> Sent
                          </IonButton>
                        );
                      }

                      if (status === 'sending') {
                        return (
                          <IonButton size="default" color="warning" disabled>
                            <IonSpinner name="lines-sharp-small"></IonSpinner> Sending
                          </IonButton>
                        );
                      }

                      return (
                        <IonButton
                          size="default"
                          color="secondary"
                          disabled={isSending}
                          onClick={() => {
                            void handleSendEmail(index);
                          }}
                        >
                          <IonIcon slot="start" icon={sendOutline} /> Send This Email
                        </IonButton>
                      );
                    })()}
                  </div>
                </div>
              </IonAccordion>
            );
          })}
        </IonAccordionGroup>
      </IonCard>
    </>
  );
}
