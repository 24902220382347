/* eslint-disable no-alert */
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
} from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import { loadSettings, saveSettings } from './settings';

export function SettingsModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  const [email, setEmail] = useState('');
  const [appPassword, setAppPassword] = useState('');

  useEffect(() => {
    const settings = loadSettings();
    if (settings) {
      setEmail(settings.email);
      setAppPassword(settings.appPassword);
    }
  }, []);

  const handleSaveClick = useCallback(() => {
    if (!email || !appPassword) {
      alert('Email or app password cannot be blank');
      return;
    }

    if (!email.endsWith('@gmail.com')) {
      alert('Email must ends with @gmail.com');
      return;
    }

    saveSettings({
      email,
      appPassword,
    });
    onDismiss();
  }, [email, appPassword]);

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mailer Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={handleSaveClick}>
              Continue
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonInput
              type="email"
              label="Gmail account"
              placeholder="name@gmail.com"
              value={email}
              onIonChange={e => {
                setEmail(e.target.value as string);
              }}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonInput
              type="password"
              label="App password"
              value={appPassword}
              onIonChange={e => {
                setAppPassword(e.target.value as string);
              }}
            ></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
}
