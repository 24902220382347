import { sortBy, take } from 'lodash';
import * as Xlsx from 'xlsx';

export class ReadReceiptXlsxError extends Error {}

export function readReceiptXlsx(fileData: ArrayBuffer) {
  const xlsx = Xlsx.read(fileData, { cellStyles: true });
  const worksheet = xlsx.Sheets[xlsx.SheetNames[0]];
  const hiddenColumnIndices = worksheet['!cols']?.reduce((indices, column, colIndex) => {
    if (column.hidden) indices.push(colIndex);
    return indices;
  }, [] as number[]);
  const hiddenRowIndices = worksheet['!rows']?.reduce((indices, row, colIndex) => {
    if (row.hidden) indices.push(colIndex);
    return indices;
  }, [] as number[]);
  const reversedHiddenColumnIndices = sortBy(hiddenColumnIndices, index => -index);
  const reversedHiddenRowIndices = sortBy(hiddenRowIndices, index => -index);

  const sheetData: string[][] = Xlsx.utils.sheet_to_json(worksheet, {
    header: 1,
    raw: false,
    blankrows: false,
  });

  const sheetWithoutHidden = sheetData.map(row => {
    const newRow = [...row];
    reversedHiddenColumnIndices.forEach(colIndex => {
      newRow.splice(colIndex, 1);
    });
    const extractedRow = take(newRow, 5);
    if (extractedRow.length !== 5)
      throw new ReadReceiptXlsxError(
        `Not enough columns, at least 5 columns are required, worksheet with ${extractedRow.length} column(s) was provided`,
      );

    return extractedRow;
  });

  reversedHiddenRowIndices.forEach(rowIndex => {
    sheetWithoutHidden.splice(rowIndex, 1);
  });

  if (sheetWithoutHidden.length <= 1) {
    throw new ReadReceiptXlsxError('Not enough rows');
  }

  return sheetWithoutHidden;
}
