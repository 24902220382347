import { z } from 'zod';

const settingsSchema = z.object({
  email: z.string(),
  appPassword: z.string(),
});

type Settings = z.infer<typeof settingsSchema>;

const settingsLsKey = 'bni-unity-mailer:settings';

export function saveSettings(settings: Settings) {
  localStorage.setItem(settingsLsKey, JSON.stringify(settings));
}

export function loadSettings() {
  const rawSettingsString = localStorage.getItem(settingsLsKey) ?? '';
  let rawSettings;
  try {
    rawSettings = JSON.parse(rawSettingsString) as unknown;
  } catch {
    return undefined;
  }

  const parseResult = settingsSchema.safeParse(rawSettings);
  return parseResult.success ? parseResult.data : undefined;
}
