import axios from 'axios';

const envApiBase = 'https://gj2szrij25.execute-api.ap-southeast-1.amazonaws.com/bni_unity_mailer';

export async function sendEmail(messageInfo: {
  sender: {
    gmail: string;
    appPassword: string;
  };
  to: string;
  subject: string;
  body: string;
  attachments: Array<{
    fileName: string;
    contentType: string;
    base64Data: string;
  }>;
}): Promise<boolean> {
  try {
    await axios.post(`${envApiBase}/email`, messageInfo);
    return true;
  } catch (e) {
    console.error('Send email error', e);
    return false;
  }
}
